.reservation-confirmation-menu-item {
    padding: 1%;
}

.reservation-confirmation-menu-item-name-wrapper {
    height: 5rem;
    width: 100%;
    margin: 0 auto;
}

.reservation-confirmation-menu-item-name-wrapper p {
    margin: 0;
}

.reservation-confirmation-page-unselected-item {
    border: 1px solid rgba(0,0,0,.1);
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    border-radius: 3px;
    background: 'white';
    
}

.reservation-confirmation-page-selected-item {
    border: 1px solid rgba(239,76,73,.5);
    box-shadow: rgba(223,60,71,.5) 0px 0px 8px 6px;
    transition: all .2s ease-in;
    /* transition: background-color .25s ease-in; */
    border-radius: 3px;
    background: rgba(0,0,0,.05);
}

.reservation-confirmation-page-unselected-item:hover, .reservation-confirmation-page-selected-item:hover {
    cursor: pointer;
}

.reservation-menu-item-button-wrapper {
    height: 4rem;
}

.reservation-confirmation-menu-item-double-side {
    position: absolute;
    padding-left: 1%;
    padding-top: 1%;
}


.reservation-confirmation-menu-label {

}

.reservation-confirmation-menu-item {

}

.reservation-confirmation-menu-picture-container {
    height: 15rem;
    overflow: hidden;
}

.reservation-confirmation-menu-picture {
    /* height: 15rem;  */
    min-height: 15rem;
    width: 100%;
    object-fit: cover;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.reservation-confirmation-favorites-image-wrapper {
    overflow: hidden;
    border-radius: 5px;
    height: 15rem;
    margin-top: .5rem;
    margin-bottom: 0rem;
}

.reservation-confirmation-favorites-image {  
    width: 100%;
    border-radius: 5px;
}

.reservation-confirmation-wrapper {
    margin: 0 auto;
    border-radius: 5px;
    padding: 2.5%;
    padding-top: 0;
}

.reservation-confirmation-header {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 0;
}

.reservation-confirmation-body {
    margin-bottom: 0;
}
/* ================== */
/* ===== MODALS ===== */
/* ================== */
.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.success-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.success-modal-header-text {
    text-align: center;
}

.success-modal-body-text {
    text-align: center;
}

.success-modal-footer {
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .success-modal-content {
        top: 15%;
        left: 30%;
        right: 30%;
        bottom: 15%;
    }

    .reservation-confirmation-wrapper {
        width: 75%;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    .reservation-confirmation-favorites-image-wrapper {
        overflow: hidden;
        border-radius: 5px;
        height: 25rem;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
    }
    
    .reservation-confirmation-favorites-image {  
        height: 25rem;
        width: auto;
        object-fit: cover;
        border-radius: 5px;
    }
}

.disable-overlay {
    max-height: 100vh;
    min-height: 100vh;
    max-width: 100vw;
    min-width: 100vw;
    overflow: hidden;
    position: fixed;
    z-index: 999;
    background: rgba(0,0,0,.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.disable-overlay-loading {
    position: absolute;
    margin-top: 20%;
    top: 0;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
}

/* ========================= */
/* ===== Google Select ===== */
/* ========================= */
.location-search-input {
    width: 95%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    margin-bottom: 0px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
}

.google-places-autocomplete__input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}
.autocomplete-dropdown-container {
    text-align: left !important;
    /* border: 1px solid black; */
    width: 45%;
    margin-left: .2rem;
    background: white;
    z-index: 100;
    position: absolute;
    /* width: 100%; */
}

.suggestion-item, .suggestion-item--active {
    border-bottom: 1px solid rgb(242, 242, 242);
    border-left: 1px solid rgb(242, 242, 242);
    border-right: 1px solid rgb(242, 242, 242);
    /* width: 100%; */
    /* border: 1px solid rgba(0, 0, 0, .1); */
    /* height: 12.5px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    /* padding-right: -5px !important; */
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
}

.suggestion:hover {
    cursor: pointer;
    background: rgb(242, 242, 242);
}

.discount-link {
    text-decoration: underline;
}

.discount-link:hover {
    cursor: pointer;
}