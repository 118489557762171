.landing-body {
    
}

.landing-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.landing-card-logo {
    height: 40%;
}

.landing-card-icon {
    height: 60%;
}

.landing-catchphrase {
    font-weight: 500;
    color: rgb(72, 72, 72);
    font-size: 28px;
}

.landing-subtitle {
    font-weight: 100;
    color: rgb(72, 72, 72);
}

@media only screen and (min-width: 768px) { 
    .landing-card {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 96px;
        margin-right: 96px;
    }

    .landing-card-logo {
        padding-top: 25px;
    }

    .reservation-form-left-column {
        padding-left: 84px;
    }
    
    .reservation-form-right-column {
        padding-left: 62px;
    }
}