.landing-body {
    
}

.landing-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.landing-card-logo {
    height: 40%;
}

.landing-card-icon {
    height: 60%;
}