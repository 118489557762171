.admin-nav-list {
    list-style-type: none;
    position: relative;
    top: -.5rem;
    left: -2.5rem;
    width: 100vw;
    margin: 0;
    height: 3.5rem;
    background: #EF4C49;
    color: white;
}
.admin-nav-link {
    display: block;
    float: left;
    margin-top: 1.5rem;
    line-height: .5rem;
    margin-left: 2.5rem;
}

.admin-nav-link:hover {
    cursor: pointer;
}