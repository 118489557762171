.landing-body {
    
}

.landing-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.landing-card-logo {
    height: 100%;
}

.landing-card-icon {
    height: 60%;
}

.confirmation__container {
    width: 90vw;
    margin: 0 auto;
    margin-left: -9vw;
}

.hidden__on__mobile {
    display: none;
}

.confirmation__header__wrapper {
    text-align: center;
    margin-bottom: 5px;
}

.confirmation__header {
    background: rgb(237, 78, 78);
    color: white;
    font-size: 26px;
    line-height: 200%;
    font-weight: 100;
    text-align: center;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.confirmation__itinerary__row {
    margin-bottom: .5rem;
}

.confirmation__card.one {
    background: rgb(237, 78, 78);
    padding-top: 5px;
    padding-left: 10px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 2rem;
    padding-bottom: 1rem;
}

.confirmation__card.two {
    background: rgb(203, 221, 228);
    padding-top: 10px;
    margin: 0 auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 70%;

}

.confirmation__card.three {
    background: rgb(245, 233, 71);
    padding-top: 10px;
    margin: 0 auto;
    text-align: center;
    width: 125%;
    margin-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -2rem;
}

.confirmation__blue__text {
    color: rgb(34, 56, 119);
}

.confirmation__body__text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 100%;
}

.confirmation__left__image {
    /* width: 20rem; */
    height: 12rem;
    width: auto;
    margin-left: -1.55rem;
    margin-top: 10px;
}

.confirmation__your__menu__header {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 0;
}

.confirmation__menu__subheader {
    /* margin-top: 0; */
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
}

.confirmation__menu__item {
    margin-bottom: 10px;
}

.confirmation__price__wrapper {
    margin: 0 auto;
    background: rgb(34, 56, 119);
    color: white;
    text-align: center;
    width: 85%;
    position: relative;
    top: 20px;
    font-size: 32px;
}

.confirmation__right__image {
    /* width: 16rem; */
    max-height: 20rem;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    top: 15px;
} 

.confirmation__we__got__dishes {
    color: rgb(34, 56, 119);
    font-size: 38px;
    margin-top: 0;
}

.confirmation__questions {
    font-size: 14px;
    margin-bottom: 2.5rem;
}

.confirmation__questions a {
    color: black;
    text-decoration: none;
}

.shown__on__mobile {
    display: block;
}

@media only screen and (min-width: 800px) {
    .hidden__on__mobile {
        display: block;
    }

    .shown__on__mobile {
        display: none;
    }

    .confirmation__questions {
        font-size: 20px;
        margin-bottom: 2.5rem;
    }
}