/* =========================== */
/* ===== X-Small/DEFAULT ===== */
/* ========= 0 - 576 ========= */
.cost-text {
    font-size: 28px;
    margin-bottom: 0;
    margin-top: 1rem;
}
.landing-wait-text {
    margin: 0 auto;
    margin-top: 0rem;
    text-align: center;
    font-size: 80px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: none;
    text-shadow: 0px 3px rgb(176, 58, 58);
    font-family: 'Work Sans' sans-serif;
    font-weight: 650;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.landing-before-you-book-text {
    margin: 0 auto;
    text-align: center;
    font-size: 17px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: .35rem 0 .35rem 0;
    text-shadow: 0px 2px rgb(176, 58, 58);
    font-family: 'Work Sans';
    font-weight: 200 !important;
    letter-spacing: 1px;
}

.body-text {
    font-family: 'Work Sans';
}

.info-image-one-background { 
    background: rgb(34, 56, 119);
    width: 15rem;
    height: 10rem;
    margin: 0 auto;
    margin-left: .5rem;
}

.info-image-one {
    width: 100%;
    margin-top: 1.25rem;
    margin-left: 1.25rem;
}

.info-image-two-background {
    background: rgb(245, 233, 71);
    margin: 0 auto;
    margin-left: 0rem;
    width: 17rem;
    height: 15rem;
}

.info-image-two {
    height: 13rem;
    width: auto;
    margin-top: 1rem;
    margin-left: -.5rem;
}

.info-image-three-background {
    /* background: rgb(203 221 228); */
    background: rgb(237, 78, 78);
    margin-left: -.65rem;
    height: 14rem;
    width: 17rem;
}

.info-image-three {
    width: 100%;
    margin-top: 1.5rem;
    margin-left: 0rem;
}

.info-image-four-background {
    
    background: rgb(245, 233, 71);
    width: 16rem;
    height: 10rem;
    margin-left: 1.5rem;
}

.info-image-four {
    width: 100%;
    margin-top: 1.25rem;
    margin-left: -1.25rem;
}

.info-image-five-background {
    width: 16rem;
    height: 20rem;
    /* background: rgb(245, 233, 71); */
    background: rgb(203, 221, 228);
}

.info-image-five {
    width: 100%;
    border: 2px solid rgb(203, 221, 228);
    margin-left: 1.5rem;
    margin-top: -1.5rem;

}

/* Small */
/* 576 - 768 */
/* Set Window to 672 for development */
@media all and (min-width: 576px) and (max-width: 767px) {
    .cost-text {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .landing-wait-text {
        font-size: 60px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 18px;
    }

    .info-image-one-background {
        margin-bottom: 2.5rem;
        margin-left: 1.5rem;
        height: 11rem;
        width: 12rem;
    }

    .info-image-one {
        width: 17rem;
    }

    .row-one-big-text {
        font-size: 18px;
        margin-left: 5rem;
    }

    .row-one-small-text {
        font-size: 10px;
        padding-left: 5rem;
        display: block;
    }

    .row-two-text {
        font-size: 18px;
        margin-top: 0rem;
        margin-bottom: 0rem; 
        margin-left: 1.25rem;
    }

    .row-two-small-text {
        font-size: 10px;
        padding-left: 5rem;
        display: block;
        margin-bottom: 1.5rem;
    }

    .info-image-two-background {
        width: 11rem;
        height: 13rem;
        margin-left: 5.5rem;
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    .info-image-two {
        height: 10rem;
        width: auto;
        margin-left: -1rem;
    }

    .info-image-three-background {
        width: 12rem;
        height: 9rem;
        margin-left: 3rem;
        margin-top: -1rem;
    }

    .info-image-three {
        width: 100%;
        margin-top: 1rem;
        margin-left: 0rem;
    }
    .row-three-instructions {
        text-align: left;
    }

    .row-three-big-text {
        font-size: 18px;
        text-align: left !important;
    }

    .row-three-small-text {
        font-size: 12px;
    }

    .info-image-four-background {
        width: 12.5rem;
        height: 8.5rem;
        margin-top: 1rem;
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 18px;
        margin-top: 2rem;
    }

    .info-image-five-background {
        height: 15rem;
        width: 12rem;
        margin-left: 2.5rem;
        margin-top: 1rem;
    }

    .info-image-five {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-five-text {
        font-size: 18px;
        margin-top: 1rem;
        margin-left: -1.5rem
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        margin-top: 1rem;
        font-size: 18px;
    }
}

/* Medium */
/* 768 - 992 */
/* Set window to 880 for development */
@media all and (min-width: 768px) and (max-width: 991px) {
    .cost-text {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .landing-wait-text {
        font-size: 72px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 20px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: 2rem;
        height: 13rem;
        width: 15rem;
    }

    .info-image-one {
        width: 20rem;
    }

    .row-one-big-text {
        font-size: 22px;
        margin-left: 4.5rem;
    }

    .row-one-small-text {
        font-size: 16px;
        padding-left: 3.5rem;
        display: block;
    }

    .row-two-text {
        font-size: 24px;
        margin-top: -1rem;
        margin-bottom: 0rem; 
        margin-left: 2rem;   
    }

    .row-two-small-text {
        font-size: 16px;
        padding-left: 5rem;
        display: block;
        margin-bottom: 1.75rem;
        margin-top: 0rem;
    }

    .info-image-two-background {
        width: 12rem;
        height: 15rem;
        margin-left: 5rem;
        margin-top: -.5rem;
        margin-bottom: 4rem;
    }

    .info-image-two {
        height: 13rem;
        width: auto;
        margin-left: 1rem;
    }

    .info-image-three-background {
        width: 16rem;
        height: 12rem;
        margin-left: 4rem;
        margin-top: -1rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: .5rem;
        margin-left: -1rem;
    }

    .row-three-big-text {
        font-size: 24px;
        margin-left: 1rem;
        display: block;
        text-align: left !important;
        line-height: 115% !important;
    }

    .row-three-small-text {
        font-size: 16px;
        margin-left: .7rem
    }

    .info-image-four-background {
        width: 15.5rem;
        height: 10.5rem;
        margin-top: 1rem;
        margin-left: .5rem
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 24px;
        margin-top: 3rem    
    }

    .info-image-five-background {
        height: 19rem;
        width: 15rem;
        margin-left: 4.5rem;
        margin-top: 2rem
    }

    .info-image-five {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-five-text {
        font-size: 24px;
        margin-top: 4rem;
        margin-left: .5rem;
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        font-size: 24px;
    }
}

/* Large */
/* 992 - 1200 */
/* Set window to 1096 for development */
@media all and (min-width: 992px) and (max-width: 1199px) {
    .cost-text {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .graphic-container {
        width: 75%;
        margin: 0 auto;
    }

    .landing-wait-text {
        font-size: 80px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 24px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: -.5rem;
        height: 15rem;
        width: 19rem;
    }

    .info-image-one {
        width: 24rem;
    }

    .row-one-instructions {
        width: 150%;
    }

    .row-one-big-text {
        font-size: 32px;
        margin-left: 5rem;
    }

    .row-one-small-text {
        font-size: 20px;
        padding-left: 4rem;
        display: block;
    }

    .row-two-text {
        font-size: 30px;
        margin-top: 1.5rem;
        margin-bottom: 0rem; 
        margin-left: -.25rem;   
    }

    .row-two-small-text {
        font-size: 20px;
        padding-left: 4rem;
        display: block;
        margin-top: 0rem;
        margin-bottom: 2.5rem;
    }

    .info-image-two-background {
        width: 13.5rem;
        height: 15rem;
        margin-left: 6rem;
        margin-top: -.5rem;
        margin-bottom: 6rem;
    }

    .info-image-two {
        height: 14rem;
        width: auto;
    }

    .info-image-three-background {
        width: 16rem;
        height: 12rem;
        margin-left: 2rem;
        margin-top: -1.5rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: .5rem;
        margin-left: -1rem;
    }

    .row-three-instructions {
        width: 150%;
    }

    .row-three-big-text {
        font-size: 28px;
        margin-left: 1rem;
        display: block;
        text-align: left !important;
        line-height: 115% !important;
    }

    .row-three-small-text {
        font-size: 19px;
        margin-left: 1.4rem;
    }

    .info-image-four-background {
        width: 18rem;
        height: 12rem;
        margin-top: 1rem;
        margin-left: 2rem
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 28px;
        margin-top: 3.5rem    
    }

    .info-image-five-background {
        height: 21rem;
        width: 16rem;
        margin-left: 2.5rem;
        margin-top: 0rem
    }

    .info-image-five {
        margin-left: 1rem;
        margin-top: -.5rem;
    }

    .row-five-text {
        font-size: 28px;
        margin-top: 3rem;
        /* margin-left: -2.5rem; */
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        margin-top: 1rem;
        font-size: 28px;
    }
}

/* X-Large */
/* 1200 - 1600 */
/* Set window to 1600 for development */
@media all and (min-width: 1200px) {
    .cost-text {
        font-size: 32px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .graphic-container {
        width: 70%;
        margin: 0 auto;
    }

    .landing-wait-text {
        font-size: 80px;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 28px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: -.5rem;
        height: 17rem;
        width: 22.5rem;
    }

    .info-image-one {
        width: 27rem;
    }

    .row-one-instructions {
        width: 150%;
    }

    .row-one-big-text {
        font-size: 32px;
        margin-left: 7rem;
    }

    .row-one-small-text {
        font-size: 20px;
        padding-left: 6rem;
        display: block;
    }

    .row-two-text {
        font-size: 32px;
        margin-top: .5rem;
        margin-bottom: 0rem;    
    }

    .row-two-small-text {
        font-size: 20px;
        padding-left: 5rem;
        display: block;
        margin-bottom: 2.5rem;
        margin-top: 0rem;
    }

    .info-image-two-background {
        width: 16rem;
        height: 19rem;
        margin-left: 8.5rem;
        margin-top: 0rem;
        margin-bottom: 6rem;
    }
    
    .info-image-two {
        margin-top: 1.5rem;
        margin-left: 1rem;
        height: 18rem;
        width: auto;
    }

    .info-image-three-background {
        width: 20rem;
        height: 16rem;
        margin-left: 2rem;
        margin-top: -1rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: 1rem;
        margin-left: 1rem;
    }

    .row-three-big-text {
        margin-left: 1.5rem;
        display: block;
        font-size: 27px;
        text-align: left !important;
        line-height: 115% !important;
        width: 139%;
    }

    .row-three-small-text {
        font-size: 20px;
        margin-left: .5rem;
    }

    .info-image-four-background {
        width: 23rem;
        height: 15.5rem;
        margin-top: 1.5rem;
        margin-left: 3rem
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 32px;
        margin-top: 3rem    
    }

    .info-image-five-background {
        height: 25rem;
        width: 20rem;
        margin-left: 3rem;
        margin-top: 1rem
    }

    .info-image-five {
        margin-left: 1.5rem;
        margin-top: -1.5rem;
    }

    .row-five-text {
        font-size: 32px;
        margin-top: 5rem;
        margin-left: 3rem !important;
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        font-size: 32px;
    }
}

/* HUGE */
@media all and (min-width: 1800px) {
    .landing-wait-text {
        font-size: 150px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 34px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: -.5rem;
        height: 27rem;
        width: 38rem;
    }

    .info-image-one {
        width: 43rem;
    }

    .row-one-instructions {
        width: 150%;
    }

    .row-one-big-text {
        font-size: 50px;
        margin-left: 11.5rem;
    }

    .row-one-small-text {
        font-size: 30px;
        padding-left: 11.5rem;
        display: block;
    }

    .row-two-text {
        font-size: 52px;
        margin-top: 2rem;
        margin-left: -.5rem;
        margin-bottom: 0rem;    
    }

    .row-two-small-text {
        font-size: 30px;
        padding-left: 5rem;
        display: block;
    }

    .info-image-two-background {
        width: 24rem;
        height: 26rem;
        margin-left: 14rem;
        margin-top: 0rem;
        margin-bottom: 13rem;
    }
    
    .info-image-two {
        margin-top: 2.5rem;
        margin-left: -2.5rem;
        height: 35rem;
        width: auto;
    }

    .info-image-three-background {
        width: 33rem;
        height: 28rem;
        margin-left: 1.5rem;
        margin-top: 0rem;
        margin-bottom: -1.5rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: 2.5rem;
        margin-left: 2.5rem;
    }

    .row-three-big-text {
        font-size: 52px;
        margin-left: 3.5rem;
        display: block;
    }

    .row-three-small-text {
        font-size: 32px;
        margin-left: -1.5rem
    }

    .info-image-four-background {
        width: 30rem;
        height: 20rem;
        margin-top: 2.5rem;
        margin-left: 3rem
    }

    .info-image-four {
        margin-left: 2rem;
        margin-top: -2rem;
    }

    .row-four-text {
        font-size: 50px;
        margin-top: 5rem    
    }

    .info-image-five-background {
        height: 37rem;
        width: 30rem;
        margin-left: 1rem;
        margin-top: 1rem
    }

    .info-image-five {
        margin-left: 2.5rem;
        margin-top: -2.5rem;
    }

    .row-five-text {
        font-size: 54px;
        margin-top: 7rem;
        margin-left: -2rem;
    }

    .row-five-text-row-two {
        margin-left: 1.1rem;
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        font-size: 50px;
    }
}
/* .landing-wait-text {
    margin: 0 auto;
    text-align: center;
    font-size: 225px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: none;
    text-shadow: 0px 3px rgb(176, 58, 58);
    font-family: 'Work Sans' sans-serif;
    font-weight: 650;
}

.landing-before-you-book-text {
    margin: 0 auto;
    text-align: center;
    font-size: 50px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: none;
    text-shadow: 0px 2px rgb(176, 58, 58);
    font-family: 'Work Sans';
    font-weight: 200 !important;
    letter-spacing: 1px;
}

.body-text {
    font-family: 'Work Sans';
}

.info-image-one-background { 
    background: rgb(34, 56, 119);
    padding-left: 1.75rem;
    padding-top: 1.75rem;
    height: 25rem;
    width: 27.5rem;
}

.info-image-one {
    width: 135%;
}

.info-image-two-background {
    background: rgb(245, 233, 71);
    margin: 0 auto;
    margin-left: 1.5rem;
    width: 24rem;
    height: 24rem;
}

.info-image-two {
    width: 100%;
    margin-top: 2rem;
    margin-left: -2rem;
}

.info-image-three-background {
    background: rgb(203 221 228);
    margin-left: 2rem;
    height: 24.5rem;
    width: 24.5rem;
}

.info-image-three {
    width: 110%;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
} */