.primary-button-large-container {
    margin: 8px;
}

.primary-button-large {
    background-color: #EF4C49;
    color: #FFFFFF;
    border: none;
    border-radius: 2.5px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 20px;
}

.primary-button-large:hover {
    cursor: pointer;
}

.primary-button-large:focus {
    outline: none;
}

.primary-button-large:active {
    background-color: #DF3C47;
}

.primary-button-large:disabled {
    background-color: #F5F5F5;
    color: rgba(0,0,0,0.25);
    border: 1px solid #D9D9D9;
    cursor: default;
}