.dashboard-tabs-list {
    list-style-type: none;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.dashboard-tabs-list-element {
    float: none;
    display: inline-block;
    margin-bottom: 3px;
}

.dashboard-tabs-text {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    /* padding-bottom: 10px; */
}

.dashboard-tabs-text:hover {
    cursor: pointer;
}