.confirmation-page-label {
    margin: 0;
}

.confirmation-page-value {
    margin-top: 5px;
}

.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.success-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.success-modal-header-text {
    text-align: center;
}

.success-modal-body-text {
    text-align: center;
}

.success-modal-footer {
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .success-modal-content {
        top: 15%;
        left: 30%;
        right: 30%;
        bottom: 15%;
    }
}