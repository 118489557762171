/* =========================== */
/* ===== X-Small/DEFAULT ===== */
/* ========= 0 - 576 ========= */
.reservation-email-error-row {
    margin-top: 0;
}

.reservation-email-error-text {
    margin-top: 0;
    font-size: 12px;
}
.reservation-card-header {
    margin: 0 auto;
    margin-bottom: .5rem;
}

.reservation-card-logo {
    /* height: 100%; */
    width: 100%;
    margin-top: 1.5rem;
    /* margin-bottom: 0; */
}

.reservation-card-icon {
    width: 90%;
}

.reservation-form-container {
}

.dietary-preferences-text-reg {
    margin-top: 0;
    margin-bottom: 0;  
}

.dietary-preferences-text-bold-italic {
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
    font-weight: bold;
}

.dietary-preferences-card {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 2.5%;
}

.dietary-preference-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    margin-top: .65rem;
    margin-bottom: .65rem;
}

.dietary-preference-checkbox-wrapper {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 1rem;
}

.dietary-preference-checkbox {
    transform: scale(2.5);
    margin-right: 2rem;
    margin-left: 1rem;
    outline: black;
    border: blue;
}

/* ======================= */
/* ===== DATE PICKER ===== */
/* ======================= */
.SingleDatePicker {

}

.SingleDatePickerInput__withBorder {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    padding-top: -2.5px;
    margin: 2px;
    margin-left: -7.5px !important;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    width: 100%;
}

.DateInput {
    width: 100% !important;
    background: none !important;
    margin-top: -.5rem;
}

.DateInput_input {
    line-height: 0 !important;
    font-weight: 0 !important; 
    background-color: none;
    padding: 0 !important; 
    border: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important; 
    width: 90% !important;
    border: none !important;
    background: none !important;
    padding: 0 !important;
    line-height: auto !important;
}

/* ================== */
/* ===== MODALS ===== */
/* ================== */
.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.success-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.success-modal-header-text {
    text-align: center;
}

.success-modal-body-text {
    text-align: center;
}

.success-modal-footer {
    text-align: center;
}

/* Small */
/* 576 - 768 */
/* Set Window to 672 for development */
@media all and (min-width: 576px) and (max-width: 767px) {

}

/* Medium */
/* 768 - 992 */
/* Set window to 880 for development */
@media all and (min-width: 768px) and (max-width: 991px) {

}

/* Large */
/* 992 - 1200 */
/* Set window to 1096 for development */
@media all and (min-width: 992px) and (max-width: 1199px) {

}

/* X-Large */
/* 1200 - 1600 */
/* Set window to 1600 for development */
@media all and (min-width: 1200px) {

}

@media only screen and (min-width: 600px) {
    .success-modal-content {
        top: 15%;
        left: 30%;
        right: 30%;
        bottom: 15%;
    }
}