.wrapper {
    padding: 0;
}

.textarea-field-wrapper {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    height: 100px;
    resize: none;

    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    /* color: rgba(0, 0, 0, 0.3); */
}

.textarea-field-wrapper::placeholder {
    color: rgba(0, 0, 0, 0.3);
}