.login-body {
    
}

.login-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.login-card-logo {
    height: 40%;
}

.login-card-icon {
    height: 60%;
}

@media only screen and (min-width: 768px) { 
    .login-card {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 96px;
        margin-right: 96px;
    }

    .login-card-logo {
        padding-top: 25px;
    }
}