.primary-button-container {
    margin: 8px;
}

.primary-button {
    background-color: #EF4C49;
    color: #FFFFFF;
    border: none;
    border-radius: 2.5px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.primary-button:hover {
    cursor: pointer;
}

.primary-button:focus {
    outline: none;
}

.primary-button:active {
    background-color: #DF3C47;
}

.primary-button:disabled {
    background-color: #F5F5F5;
    color: rgba(0,0,0,0.25);
    border: 1px solid #D9D9D9;
    cursor: default;
}