p, h1, h2, h3, h4, h5 {
    font-family: 'Work Sans' sans-serif !important;
}

.delivery__container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);
    padding-bottom: 1rem;
}

.delivery__logo {
    width: 100%;
    margin-top: 1.5rem;
}

.delivery__instructions__header {
    font-size: 46px;
    color: rgb(216, 70, 72);
}

.delivery__instructions__one {
    font-size: 20px;
    background: rgb(216, 70, 72);
    color: white;
    font-weight: 200;
    letter-spacing: .1rem;
    line-height: 150%;
    padding-top: .05rem;
    padding-bottom: 1rem;
    padding-left: .5rem;
    padding-right: 1.5rem;
}

.delivery__instructions__two {
    font-size: 20px;
    background: rgb(2, 12, 126);
    color: white;
    font-weight: 600;
    line-height: 150%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: .5rem;
    margin-top: 5rem;
}

.delivery__instructions__three {
    font-size: 22px;
    background: rgb(2, 12, 126);
    color: white;
    font-weight: 600;
    line-height: 150%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}

.delivery__instructions__four {
    font-size: 48px;
    background: rgb(252, 13, 27);
    color: white;
    margin-bottom: 0;
}

.delivery__instructions__five {
    font-size: 20px;
    background: rgb(252, 13, 27);
    color: white;
}

.DayPickerNavigation_button {
    /* display: none; */
}

.DateInput_fang.DateInput_fang_1 {
    display: none;
}
.SingleDatePickerInput__withBorder {
    border: none;
    height: 0;
    padding: 0;
}
.SingleDatePicker {
    margin-left: -129%;
}
/* BRIGHT RED */
/* rgb(252, 13, 27) */
/* MUTED RED */
/* rgb(216, 70, 72) */
/* BLUE */
/* rgb(2, 12, 126) */
/* YELLOW */
/* rgb(232, 206, 124) */
p, h3 {
    font-family: 'Work Sans'
}

.receipt__header {
    background: rgb(237, 78, 78);
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.receipt__left__block {
    background: rgb(237, 78, 78);
    font-size: 18px;
}

.receipt__left__block p {
    padding-left: .75rem;
    text-align: left;
}

.receipt__left__image {
    width: 90%;
    margin: 0 auto !important;
}

.receipt__center__block {
    background: rgb(203 221 228);
    height: 20rem;
    padding-top: 1rem;
    margin-bottom: -3.25rem;
}

.receipt__menu__header {
    font-size: 34px;
    margin-top: 0px;
    font-weight: 900;
}

.receipt__menu__body {
    font-size: 24px;
    font-weight: 900;
    line-height: 200%;
}

.receipt__price__block { 
    background: rgb(34, 56, 119);
    color: white;
    text-align: left;
    padding-left: .75rem;
    font-size: 30px;
    font-weight: 700;
    width: 70%;
    margin: 0 auto;
    position: relative;
}

.receipt__right__block {
    background: rgb(245, 233, 71);
    font-size: 20px;
}

.receipt__right__image {
    width: 90%;
}

.receipt__payment__text {
    font-size: 22px;
    margin-top: 3rem;
}

.receipt__email__text {
    font-size: 24px;
    font-weight: 700;
}
@media all and (min-width: 768px) {
    .receipt__header {
        font-size: 38px;
    }
}