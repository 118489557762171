.landing-body {
    
}

.landing-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.landing-card-logo {
    height: 100%;
}

.landing-card-icon {
    height: 60%;
}

.confirmation__container {
    width: 90vw;
    margin: 0 auto;
    margin-left: -9vw;
}

.hidden__on__mobile {
    display: none;
}

.confirmation__header__wrapper {
    text-align: center;
    margin-bottom: 5px;
}

.confirmation__header {
    background: rgb(237, 78, 78);
    color: white;
    font-size: 26px;
    line-height: 200%;
    font-weight: 100;
    text-align: center;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.confirmation__itinerary__row {
    margin-bottom: .5rem;
}

.confirmation__card.one {
    background: rgb(237, 78, 78);
    padding-top: 5px;
    padding-left: 10px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    margin-top: 2rem;
    padding-bottom: 1rem;
}

.confirmation__card.two {
    background: rgb(203, 221, 228);
    padding-top: 10px;
    margin: 0 auto;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 70%;

}

.confirmation__card.three {
    background: rgb(245, 233, 71);
    padding-top: 10px;
    margin: 0 auto;
    text-align: center;
    width: 125%;
    margin-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -2rem;
}

.confirmation__blue__text {
    color: rgb(34, 56, 119);
}

.confirmation__body__text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 100%;
}

.confirmation__left__image {
    /* width: 20rem; */
    height: 12rem;
    width: auto;
    margin-left: -1.55rem;
    margin-top: 10px;
}

.confirmation__your__menu__header {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 0;
}

.confirmation__menu__subheader {
    /* margin-top: 0; */
    margin-bottom: 0;
    font-size: 20px;
    font-weight: bold;
}

.confirmation__menu__item {
    margin-bottom: 10px;
}

.confirmation__price__wrapper {
    margin: 0 auto;
    background: rgb(34, 56, 119);
    color: white;
    text-align: center;
    width: 85%;
    position: relative;
    top: 20px;
    font-size: 32px;
}

.confirmation__right__image {
    /* width: 16rem; */
    max-height: 20rem;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    top: 15px;
} 

.confirmation__we__got__dishes {
    color: rgb(34, 56, 119);
    font-size: 38px;
    margin-top: 0;
}

.confirmation__questions {
    font-size: 14px;
    margin-bottom: 2.5rem;
}

.confirmation__questions a {
    color: black;
    text-decoration: none;
}

.shown__on__mobile {
    display: block;
}

@media only screen and (min-width: 800px) {
    .hidden__on__mobile {
        display: block;
    }

    .shown__on__mobile {
        display: none;
    }

    .confirmation__questions {
        font-size: 20px;
        margin-bottom: 2.5rem;
    }
}
/* =========================== */
/* ===== X-Small/DEFAULT ===== */
/* ========= 0 - 576 ========= */
.cost-text {
    font-size: 28px;
    margin-bottom: 0;
    margin-top: 1rem;
}
.landing-wait-text {
    margin: 0 auto;
    margin-top: 0rem;
    text-align: center;
    font-size: 80px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: none;
    text-shadow: 0px 3px rgb(176, 58, 58);
    font-family: 'Work Sans' sans-serif;
    font-weight: 650;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.landing-before-you-book-text {
    margin: 0 auto;
    text-align: center;
    font-size: 17px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: .35rem 0 .35rem 0;
    text-shadow: 0px 2px rgb(176, 58, 58);
    font-family: 'Work Sans';
    font-weight: 200 !important;
    letter-spacing: 1px;
}

.body-text {
    font-family: 'Work Sans';
}

.info-image-one-background { 
    background: rgb(34, 56, 119);
    width: 15rem;
    height: 10rem;
    margin: 0 auto;
    margin-left: .5rem;
}

.info-image-one {
    width: 100%;
    margin-top: 1.25rem;
    margin-left: 1.25rem;
}

.info-image-two-background {
    background: rgb(245, 233, 71);
    margin: 0 auto;
    margin-left: 0rem;
    width: 17rem;
    height: 15rem;
}

.info-image-two {
    height: 13rem;
    width: auto;
    margin-top: 1rem;
    margin-left: -.5rem;
}

.info-image-three-background {
    /* background: rgb(203 221 228); */
    background: rgb(237, 78, 78);
    margin-left: -.65rem;
    height: 14rem;
    width: 17rem;
}

.info-image-three {
    width: 100%;
    margin-top: 1.5rem;
    margin-left: 0rem;
}

.info-image-four-background {
    
    background: rgb(245, 233, 71);
    width: 16rem;
    height: 10rem;
    margin-left: 1.5rem;
}

.info-image-four {
    width: 100%;
    margin-top: 1.25rem;
    margin-left: -1.25rem;
}

.info-image-five-background {
    width: 16rem;
    height: 20rem;
    /* background: rgb(245, 233, 71); */
    background: rgb(203, 221, 228);
}

.info-image-five {
    width: 100%;
    border: 2px solid rgb(203, 221, 228);
    margin-left: 1.5rem;
    margin-top: -1.5rem;

}

/* Small */
/* 576 - 768 */
/* Set Window to 672 for development */
@media all and (min-width: 576px) and (max-width: 767px) {
    .cost-text {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .landing-wait-text {
        font-size: 60px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 18px;
    }

    .info-image-one-background {
        margin-bottom: 2.5rem;
        margin-left: 1.5rem;
        height: 11rem;
        width: 12rem;
    }

    .info-image-one {
        width: 17rem;
    }

    .row-one-big-text {
        font-size: 18px;
        margin-left: 5rem;
    }

    .row-one-small-text {
        font-size: 10px;
        padding-left: 5rem;
        display: block;
    }

    .row-two-text {
        font-size: 18px;
        margin-top: 0rem;
        margin-bottom: 0rem; 
        margin-left: 1.25rem;
    }

    .row-two-small-text {
        font-size: 10px;
        padding-left: 5rem;
        display: block;
        margin-bottom: 1.5rem;
    }

    .info-image-two-background {
        width: 11rem;
        height: 13rem;
        margin-left: 5.5rem;
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    .info-image-two {
        height: 10rem;
        width: auto;
        margin-left: -1rem;
    }

    .info-image-three-background {
        width: 12rem;
        height: 9rem;
        margin-left: 3rem;
        margin-top: -1rem;
    }

    .info-image-three {
        width: 100%;
        margin-top: 1rem;
        margin-left: 0rem;
    }
    .row-three-instructions {
        text-align: left;
    }

    .row-three-big-text {
        font-size: 18px;
        text-align: left !important;
    }

    .row-three-small-text {
        font-size: 12px;
    }

    .info-image-four-background {
        width: 12.5rem;
        height: 8.5rem;
        margin-top: 1rem;
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 18px;
        margin-top: 2rem;
    }

    .info-image-five-background {
        height: 15rem;
        width: 12rem;
        margin-left: 2.5rem;
        margin-top: 1rem;
    }

    .info-image-five {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-five-text {
        font-size: 18px;
        margin-top: 1rem;
        margin-left: -1.5rem
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        margin-top: 1rem;
        font-size: 18px;
    }
}

/* Medium */
/* 768 - 992 */
/* Set window to 880 for development */
@media all and (min-width: 768px) and (max-width: 991px) {
    .cost-text {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .landing-wait-text {
        font-size: 72px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 20px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: 2rem;
        height: 13rem;
        width: 15rem;
    }

    .info-image-one {
        width: 20rem;
    }

    .row-one-big-text {
        font-size: 22px;
        margin-left: 4.5rem;
    }

    .row-one-small-text {
        font-size: 16px;
        padding-left: 3.5rem;
        display: block;
    }

    .row-two-text {
        font-size: 24px;
        margin-top: -1rem;
        margin-bottom: 0rem; 
        margin-left: 2rem;   
    }

    .row-two-small-text {
        font-size: 16px;
        padding-left: 5rem;
        display: block;
        margin-bottom: 1.75rem;
        margin-top: 0rem;
    }

    .info-image-two-background {
        width: 12rem;
        height: 15rem;
        margin-left: 5rem;
        margin-top: -.5rem;
        margin-bottom: 4rem;
    }

    .info-image-two {
        height: 13rem;
        width: auto;
        margin-left: 1rem;
    }

    .info-image-three-background {
        width: 16rem;
        height: 12rem;
        margin-left: 4rem;
        margin-top: -1rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: .5rem;
        margin-left: -1rem;
    }

    .row-three-big-text {
        font-size: 24px;
        margin-left: 1rem;
        display: block;
        text-align: left !important;
        line-height: 115% !important;
    }

    .row-three-small-text {
        font-size: 16px;
        margin-left: .7rem
    }

    .info-image-four-background {
        width: 15.5rem;
        height: 10.5rem;
        margin-top: 1rem;
        margin-left: .5rem
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 24px;
        margin-top: 3rem    
    }

    .info-image-five-background {
        height: 19rem;
        width: 15rem;
        margin-left: 4.5rem;
        margin-top: 2rem
    }

    .info-image-five {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-five-text {
        font-size: 24px;
        margin-top: 4rem;
        margin-left: .5rem;
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        font-size: 24px;
    }
}

/* Large */
/* 992 - 1200 */
/* Set window to 1096 for development */
@media all and (min-width: 992px) and (max-width: 1199px) {
    .cost-text {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .graphic-container {
        width: 75%;
        margin: 0 auto;
    }

    .landing-wait-text {
        font-size: 80px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 24px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: -.5rem;
        height: 15rem;
        width: 19rem;
    }

    .info-image-one {
        width: 24rem;
    }

    .row-one-instructions {
        width: 150%;
    }

    .row-one-big-text {
        font-size: 32px;
        margin-left: 5rem;
    }

    .row-one-small-text {
        font-size: 20px;
        padding-left: 4rem;
        display: block;
    }

    .row-two-text {
        font-size: 30px;
        margin-top: 1.5rem;
        margin-bottom: 0rem; 
        margin-left: -.25rem;   
    }

    .row-two-small-text {
        font-size: 20px;
        padding-left: 4rem;
        display: block;
        margin-top: 0rem;
        margin-bottom: 2.5rem;
    }

    .info-image-two-background {
        width: 13.5rem;
        height: 15rem;
        margin-left: 6rem;
        margin-top: -.5rem;
        margin-bottom: 6rem;
    }

    .info-image-two {
        height: 14rem;
        width: auto;
    }

    .info-image-three-background {
        width: 16rem;
        height: 12rem;
        margin-left: 2rem;
        margin-top: -1.5rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: .5rem;
        margin-left: -1rem;
    }

    .row-three-instructions {
        width: 150%;
    }

    .row-three-big-text {
        font-size: 28px;
        margin-left: 1rem;
        display: block;
        text-align: left !important;
        line-height: 115% !important;
    }

    .row-three-small-text {
        font-size: 19px;
        margin-left: 1.4rem;
    }

    .info-image-four-background {
        width: 18rem;
        height: 12rem;
        margin-top: 1rem;
        margin-left: 2rem
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 28px;
        margin-top: 3.5rem    
    }

    .info-image-five-background {
        height: 21rem;
        width: 16rem;
        margin-left: 2.5rem;
        margin-top: 0rem
    }

    .info-image-five {
        margin-left: 1rem;
        margin-top: -.5rem;
    }

    .row-five-text {
        font-size: 28px;
        margin-top: 3rem;
        /* margin-left: -2.5rem; */
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        margin-top: 1rem;
        font-size: 28px;
    }
}

/* X-Large */
/* 1200 - 1600 */
/* Set window to 1600 for development */
@media all and (min-width: 1200px) {
    .cost-text {
        font-size: 32px;
        margin-bottom: 1rem;
        margin-top: .5rem;
    }

    .graphic-container {
        width: 70%;
        margin: 0 auto;
    }

    .landing-wait-text {
        font-size: 80px;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 28px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: -.5rem;
        height: 17rem;
        width: 22.5rem;
    }

    .info-image-one {
        width: 27rem;
    }

    .row-one-instructions {
        width: 150%;
    }

    .row-one-big-text {
        font-size: 32px;
        margin-left: 7rem;
    }

    .row-one-small-text {
        font-size: 20px;
        padding-left: 6rem;
        display: block;
    }

    .row-two-text {
        font-size: 32px;
        margin-top: .5rem;
        margin-bottom: 0rem;    
    }

    .row-two-small-text {
        font-size: 20px;
        padding-left: 5rem;
        display: block;
        margin-bottom: 2.5rem;
        margin-top: 0rem;
    }

    .info-image-two-background {
        width: 16rem;
        height: 19rem;
        margin-left: 8.5rem;
        margin-top: 0rem;
        margin-bottom: 6rem;
    }
    
    .info-image-two {
        margin-top: 1.5rem;
        margin-left: 1rem;
        height: 18rem;
        width: auto;
    }

    .info-image-three-background {
        width: 20rem;
        height: 16rem;
        margin-left: 2rem;
        margin-top: -1rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: 1rem;
        margin-left: 1rem;
    }

    .row-three-big-text {
        margin-left: 1.5rem;
        display: block;
        font-size: 27px;
        text-align: left !important;
        line-height: 115% !important;
        width: 139%;
    }

    .row-three-small-text {
        font-size: 20px;
        margin-left: .5rem;
    }

    .info-image-four-background {
        width: 23rem;
        height: 15.5rem;
        margin-top: 1.5rem;
        margin-left: 3rem
    }

    .info-image-four {
        margin-left: 1rem;
        margin-top: -1rem;
    }

    .row-four-text {
        font-size: 32px;
        margin-top: 3rem    
    }

    .info-image-five-background {
        height: 25rem;
        width: 20rem;
        margin-left: 3rem;
        margin-top: 1rem
    }

    .info-image-five {
        margin-left: 1.5rem;
        margin-top: -1.5rem;
    }

    .row-five-text {
        font-size: 32px;
        margin-top: 5rem;
        margin-left: 3rem !important;
    }

    .row-five-text-row-two {
        /* margin-left: 1.1rem; */
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        font-size: 32px;
    }
}

/* HUGE */
@media all and (min-width: 1800px) {
    .landing-wait-text {
        font-size: 150px;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .landing-before-you-book-text {
        font-size: 34px;
    }

    .info-image-one-background {
        margin-bottom: 3.5rem;
        margin-left: -.5rem;
        height: 27rem;
        width: 38rem;
    }

    .info-image-one {
        width: 43rem;
    }

    .row-one-instructions {
        width: 150%;
    }

    .row-one-big-text {
        font-size: 50px;
        margin-left: 11.5rem;
    }

    .row-one-small-text {
        font-size: 30px;
        padding-left: 11.5rem;
        display: block;
    }

    .row-two-text {
        font-size: 52px;
        margin-top: 2rem;
        margin-left: -.5rem;
        margin-bottom: 0rem;    
    }

    .row-two-small-text {
        font-size: 30px;
        padding-left: 5rem;
        display: block;
    }

    .info-image-two-background {
        width: 24rem;
        height: 26rem;
        margin-left: 14rem;
        margin-top: 0rem;
        margin-bottom: 13rem;
    }
    
    .info-image-two {
        margin-top: 2.5rem;
        margin-left: -2.5rem;
        height: 35rem;
        width: auto;
    }

    .info-image-three-background {
        width: 33rem;
        height: 28rem;
        margin-left: 1.5rem;
        margin-top: 0rem;
        margin-bottom: -1.5rem;
    }

    .info-image-three {
        width: 102%;
        margin-top: 2.5rem;
        margin-left: 2.5rem;
    }

    .row-three-big-text {
        font-size: 52px;
        margin-left: 3.5rem;
        display: block;
    }

    .row-three-small-text {
        font-size: 32px;
        margin-left: -1.5rem
    }

    .info-image-four-background {
        width: 30rem;
        height: 20rem;
        margin-top: 2.5rem;
        margin-left: 3rem
    }

    .info-image-four {
        margin-left: 2rem;
        margin-top: -2rem;
    }

    .row-four-text {
        font-size: 50px;
        margin-top: 5rem    
    }

    .info-image-five-background {
        height: 37rem;
        width: 30rem;
        margin-left: 1rem;
        margin-top: 1rem
    }

    .info-image-five {
        margin-left: 2.5rem;
        margin-top: -2.5rem;
    }

    .row-five-text {
        font-size: 54px;
        margin-top: 7rem;
        margin-left: -2rem;
    }

    .row-five-text-row-two {
        margin-left: 1.1rem;
    }

    .row-five-text-row-three {
        margin-left: 2.2rem;
    }

    .row-six-text {
        font-size: 50px;
    }
}
/* .landing-wait-text {
    margin: 0 auto;
    text-align: center;
    font-size: 225px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: none;
    text-shadow: 0px 3px rgb(176, 58, 58);
    font-family: 'Work Sans' sans-serif;
    font-weight: 650;
}

.landing-before-you-book-text {
    margin: 0 auto;
    text-align: center;
    font-size: 50px;
    margin-left: none;
    color: white;
    background: rgb(237, 78, 78);
    padding: none;
    text-shadow: 0px 2px rgb(176, 58, 58);
    font-family: 'Work Sans';
    font-weight: 200 !important;
    letter-spacing: 1px;
}

.body-text {
    font-family: 'Work Sans';
}

.info-image-one-background { 
    background: rgb(34, 56, 119);
    padding-left: 1.75rem;
    padding-top: 1.75rem;
    height: 25rem;
    width: 27.5rem;
}

.info-image-one {
    width: 135%;
}

.info-image-two-background {
    background: rgb(245, 233, 71);
    margin: 0 auto;
    margin-left: 1.5rem;
    width: 24rem;
    height: 24rem;
}

.info-image-two {
    width: 100%;
    margin-top: 2rem;
    margin-left: -2rem;
}

.info-image-three-background {
    background: rgb(203 221 228);
    margin-left: 2rem;
    height: 24.5rem;
    width: 24.5rem;
}

.info-image-three {
    width: 110%;
    margin-top: 1.5rem;
    margin-left: 1.5rem;
} */
.primary-button-container {
    margin: 8px;
}

.primary-button {
    background-color: #EF4C49;
    color: #FFFFFF;
    border: none;
    border-radius: 2.5px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.primary-button:hover {
    cursor: pointer;
}

.primary-button:focus {
    outline: none;
}

.primary-button:active {
    background-color: #DF3C47;
}

.primary-button:disabled {
    background-color: #F5F5F5;
    color: rgba(0,0,0,0.25);
    border: 1px solid #D9D9D9;
    cursor: default;
}
.primary-button-large-container {
    margin: 8px;
}

.primary-button-large {
    background-color: #EF4C49;
    color: #FFFFFF;
    border: none;
    border-radius: 2.5px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 20px;
}

.primary-button-large:hover {
    cursor: pointer;
}

.primary-button-large:focus {
    outline: none;
}

.primary-button-large:active {
    background-color: #DF3C47;
}

.primary-button-large:disabled {
    background-color: #F5F5F5;
    color: rgba(0,0,0,0.25);
    border: 1px solid #D9D9D9;
    cursor: default;
}
.wrapper {
    padding: 0;
}

.input-field-wrapper {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    /* color: rgba(0, 0, 0, 0.3); */
}

.input-field-wrapper::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.input-field-wrapper::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.input-field-wrapper::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.input-error-container {
    height: 22px;
    padding: 2px;
}

.input-error-message {
    margin: 0;
}

/* =========================== */
/* ===== X-Small/DEFAULT ===== */
/* ========= 0 - 576 ========= */
.reservation-email-error-row {
    margin-top: 0;
}

.reservation-email-error-text {
    margin-top: 0;
    font-size: 12px;
}
.reservation-card-header {
    margin: 0 auto;
    margin-bottom: .5rem;
}

.reservation-card-logo {
    /* height: 100%; */
    width: 100%;
    margin-top: 1.5rem;
    /* margin-bottom: 0; */
}

.reservation-card-icon {
    width: 90%;
}

.reservation-form-container {
}

.dietary-preferences-text-reg {
    margin-top: 0;
    margin-bottom: 0;  
}

.dietary-preferences-text-bold-italic {
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
    font-weight: bold;
}

.dietary-preferences-card {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 2.5%;
}

.dietary-preference-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    margin-top: .65rem;
    margin-bottom: .65rem;
}

.dietary-preference-checkbox-wrapper {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 1rem;
}

.dietary-preference-checkbox {
    -webkit-transform: scale(2.5);
            transform: scale(2.5);
    margin-right: 2rem;
    margin-left: 1rem;
    outline: black;
    border: blue;
}

/* ======================= */
/* ===== DATE PICKER ===== */
/* ======================= */
.SingleDatePicker {

}

.SingleDatePickerInput__withBorder {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    padding-top: -2.5px;
    margin: 2px;
    margin-left: -7.5px !important;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    width: 100%;
}

.DateInput {
    width: 100% !important;
    background: none !important;
    margin-top: -.5rem;
}

.DateInput_input {
    line-height: 0 !important;
    font-weight: 0 !important; 
    background-color: none;
    padding: 0 !important; 
    border: 0 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important; 
    width: 90% !important;
    border: none !important;
    background: none !important;
    padding: 0 !important;
    line-height: auto !important;
}

/* ================== */
/* ===== MODALS ===== */
/* ================== */
.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.success-modal-content {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.success-modal-header-text {
    text-align: center;
}

.success-modal-body-text {
    text-align: center;
}

.success-modal-footer {
    text-align: center;
}

/* Small */
/* 576 - 768 */
/* Set Window to 672 for development */
@media all and (min-width: 576px) and (max-width: 767px) {

}

/* Medium */
/* 768 - 992 */
/* Set window to 880 for development */
@media all and (min-width: 768px) and (max-width: 991px) {

}

/* Large */
/* 992 - 1200 */
/* Set window to 1096 for development */
@media all and (min-width: 992px) and (max-width: 1199px) {

}

/* X-Large */
/* 1200 - 1600 */
/* Set window to 1600 for development */
@media all and (min-width: 1200px) {

}

@media only screen and (min-width: 600px) {
    .success-modal-content {
        top: 15%;
        left: 30%;
        right: 30%;
        bottom: 15%;
    }
}
.reservation-confirmation-menu-item {
    padding: 1%;
}

.reservation-confirmation-menu-item-name-wrapper {
    height: 5rem;
    width: 100%;
    margin: 0 auto;
}

.reservation-confirmation-menu-item-name-wrapper p {
    margin: 0;
}

.reservation-confirmation-page-unselected-item {
    border: 1px solid rgba(0,0,0,.1);
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    border-radius: 3px;
    background: 'white';
    
}

.reservation-confirmation-page-selected-item {
    border: 1px solid rgba(239,76,73,.5);
    box-shadow: rgba(223,60,71,.5) 0px 0px 8px 6px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    /* transition: background-color .25s ease-in; */
    border-radius: 3px;
    background: rgba(0,0,0,.05);
}

.reservation-confirmation-page-unselected-item:hover, .reservation-confirmation-page-selected-item:hover {
    cursor: pointer;
}

.reservation-menu-item-button-wrapper {
    height: 4rem;
}

.reservation-confirmation-menu-item-double-side {
    position: absolute;
    padding-left: 1%;
    padding-top: 1%;
}


.reservation-confirmation-menu-label {

}

.reservation-confirmation-menu-item {

}

.reservation-confirmation-menu-picture-container {
    height: 15rem;
    overflow: hidden;
}

.reservation-confirmation-menu-picture {
    /* height: 15rem;  */
    min-height: 15rem;
    width: 100%;
    object-fit: cover;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.reservation-confirmation-favorites-image-wrapper {
    overflow: hidden;
    border-radius: 5px;
    height: 15rem;
    margin-top: .5rem;
    margin-bottom: 0rem;
}

.reservation-confirmation-favorites-image {  
    width: 100%;
    border-radius: 5px;
}

.reservation-confirmation-wrapper {
    margin: 0 auto;
    border-radius: 5px;
    padding: 2.5%;
    padding-top: 0;
}

.reservation-confirmation-header {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 0;
}

.reservation-confirmation-body {
    margin-bottom: 0;
}
/* ================== */
/* ===== MODALS ===== */
/* ================== */
.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.success-modal-content {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.success-modal-header-text {
    text-align: center;
}

.success-modal-body-text {
    text-align: center;
}

.success-modal-footer {
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .success-modal-content {
        top: 15%;
        left: 30%;
        right: 30%;
        bottom: 15%;
    }

    .reservation-confirmation-wrapper {
        width: 75%;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    .reservation-confirmation-favorites-image-wrapper {
        overflow: hidden;
        border-radius: 5px;
        height: 25rem;
        margin-top: .5rem;
        margin-bottom: 1.5rem;
    }
    
    .reservation-confirmation-favorites-image {  
        height: 25rem;
        width: auto;
        object-fit: cover;
        border-radius: 5px;
    }
}

.disable-overlay {
    max-height: 100vh;
    min-height: 100vh;
    max-width: 100vw;
    min-width: 100vw;
    overflow: hidden;
    position: fixed;
    z-index: 999;
    background: rgba(0,0,0,.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.disable-overlay-loading {
    position: absolute;
    margin-top: 20%;
    top: 0;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
}

/* ========================= */
/* ===== Google Select ===== */
/* ========================= */
.location-search-input {
    width: 95%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    margin-bottom: 0px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
}

.google-places-autocomplete__input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.google-places-autocomplete__input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.google-places-autocomplete__input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}
.autocomplete-dropdown-container {
    text-align: left !important;
    /* border: 1px solid black; */
    width: 45%;
    margin-left: .2rem;
    background: white;
    z-index: 100;
    position: absolute;
    /* width: 100%; */
}

.suggestion-item, .suggestion-item--active {
    border-bottom: 1px solid rgb(242, 242, 242);
    border-left: 1px solid rgb(242, 242, 242);
    border-right: 1px solid rgb(242, 242, 242);
    /* width: 100%; */
    /* border: 1px solid rgba(0, 0, 0, .1); */
    /* height: 12.5px; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    /* padding-right: -5px !important; */
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
}

.suggestion:hover {
    cursor: pointer;
    background: rgb(242, 242, 242);
}

.discount-link {
    text-decoration: underline;
}

.discount-link:hover {
    cursor: pointer;
}
.sq-fieldset {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    width: 70%;
}


.square-payment-form-card-submit-container {
    margin: 8px;
}

.square-payment-form-card-submit {
    margin: 8px;
}

.square-payment-form-card-submit button {
    padding-top: 0px;
    padding-bottom: 0px;
}
button {
    background-color: #EF4C49;
    color: #FFFFFF;
    border: none;
    border-radius: 2.5px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: none;
}

button:active {
    background-color: #DF3C47;
}

button:disabled {
    background-color: #F5F5F5;
    color: rgba(0,0,0,0.25);
    border: 1px solid #D9D9D9;
    cursor: default;
}

button:hover {
    cursor: pointer;
}

.credit-card-wrapper {
    /* width: 75%; */
}
.credit-card-input-field {
    border-bottom: 1px solid black !important;
    padding-left: .5rem;
    margin-bottom: 1rem;
}
/* These styles are used if a demo specific stylesheet is not present */
body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: 'Work Sans';
}

#stripe-form form {
  max-width: 1200px;
  margin: 80px auto;
  margin-top: 0px;
}

#stripe-form label {
  color: black;
  font-weight: 500;
  letter-spacing: 0.025em;
  margin-top: 16px;
  /* display: block; */
}

#stripe-form button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #EF4C49;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

#stripe-form button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #EF4C49;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

#stripe-form button[disabled] {
  opacity: 0.6;
}

#stripe-form input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

#stripe-form input::-webkit-input-placeholder {
  color: #aab7c4;
}

#stripe-form input::-ms-input-placeholder {
  color: #aab7c4;
}

#stripe-form input::placeholder {
  color: #aab7c4;
}

#stripe-form .result,
#stripe-form .error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

#stripe-form .error {
  color: #e4584c;
}

#stripe-form .result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementFpx {
  padding: 0;
}
.landing-body {
    
}

.landing-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.landing-card-logo {
    height: 40%;
}

.landing-card-icon {
    height: 60%;
}

.landing-catchphrase {
    font-weight: 500;
    color: rgb(72, 72, 72);
    font-size: 28px;
}

.landing-subtitle {
    font-weight: 100;
    color: rgb(72, 72, 72);
}

@media only screen and (min-width: 768px) { 
    .landing-card {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 96px;
        margin-right: 96px;
    }

    .landing-card-logo {
        padding-top: 25px;
    }

    .reservation-form-left-column {
        padding-left: 84px;
    }
    
    .reservation-form-right-column {
        padding-left: 62px;
    }
}
.login-body {
    
}

.login-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.login-card-logo {
    height: 40%;
}

.login-card-icon {
    height: 60%;
}

@media only screen and (min-width: 768px) { 
    .login-card {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 96px;
        margin-right: 96px;
    }

    .login-card-logo {
        padding-top: 25px;
    }
}
.wrapper {
    padding: 0;
}

.textarea-field-wrapper {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    height: 100px;
    resize: none;

    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    /* color: rgba(0, 0, 0, 0.3); */
}

.textarea-field-wrapper::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.textarea-field-wrapper::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.textarea-field-wrapper::placeholder {
    color: rgba(0, 0, 0, 0.3);
}
.confirmation-page-label {
    margin: 0;
}

.confirmation-page-value {
    margin-top: 5px;
}

.success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.success-modal-content {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.success-modal-header-text {
    text-align: center;
}

.success-modal-body-text {
    text-align: center;
}

.success-modal-footer {
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .success-modal-content {
        top: 15%;
        left: 30%;
        right: 30%;
        bottom: 15%;
    }
}
.dashboard-body {
    
}

.dashboard-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0 16px 40px rgba(0,0,0,0.12);
}

.dashboard-card-logo {
    height: 40%;
}

.dashboard-card-icon {
    height: 60%;
}

.reservation-container {
    /* border: 2px solid black; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 15px;
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
}

.reservation-name {
    margin-top: 0;
}

.reservation-column-header {
    margin-top: 0;
}

.details-container {
    padding: 0px 17px 17px 17px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
}

.dietary-restriction-tag-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    padding: 10px;
    margin-right: 5px;
}

.dietary-restriction-tag {
    margin: 0 auto;
}

.slot-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
}

.slot-container.empty {
    padding-top: 17px;
    padding-bottom: 17px;
}

.slot-text {
    margin: 0; 
    margin-left: 8px;
}

.slot-text.not-current {
    padding-top: 17px;
    padding-bottom: 17px;
}

@media only screen and (min-width: 768px) { 
    .dashboard-card {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 96px;
        margin-right: 96px;
    }

    .dashboard-card-logo {
        padding-top: 25px;
    }
}
.landing-body {
    
}

.landing-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);

}

.landing-card-logo {
    height: 40%;
}

.landing-card-icon {
    height: 60%;
}
.admin-nav-list {
    list-style-type: none;
    position: relative;
    top: -.5rem;
    left: -2.5rem;
    width: 100vw;
    margin: 0;
    height: 3.5rem;
    background: #EF4C49;
    color: white;
}
.admin-nav-link {
    display: block;
    float: left;
    margin-top: 1.5rem;
    line-height: .5rem;
    margin-left: 2.5rem;
}

.admin-nav-link:hover {
    cursor: pointer;
}
.dashboard-tabs-list {
    list-style-type: none;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.dashboard-tabs-list-element {
    float: none;
    display: inline-block;
    margin-bottom: 3px;
}

.dashboard-tabs-text {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 0;
    /* padding-bottom: 10px; */
}

.dashboard-tabs-text:hover {
    cursor: pointer;
}
.application-container {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
}

.invitation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.invitation-modal-content {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.invitation-modal-close:hover {
    cursor: pointer;
}

.invitation-modal-header-text {
    text-align: center;
}

.invitation-modal-body-text {
    text-align: center;
}

.invitation-modal-footer {
    text-align: center;
}

.denial-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.denial-modal-content {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.denial-modal-close:hover {
    cursor: pointer;
}

.denial-modal-header-text {
    text-align: center;
}

.denial-modal-body-text {
    text-align: center;
}

.denial-modal-footer {
    text-align: center;
}

.contact-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.contact-modal-content {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.contact-modal-close:hover {
    cursor: pointer;
}

.contact-modal-header-text {
    text-align: center;
}

.contact-modal-body-text {
    text-align: center;
}

.contact-modal-footer {
    text-align: center;
}
.reservation-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-bottom: 2.5%;
}

.confirmed-reservation-header {
    font-weight: bold;
    font-size:18px;
}

.confirmed-reservation-label {
    font-weight: bold;
}
p, h1, h2, h3, h4, h5 {
    font-family: 'Work Sans' sans-serif !important;
}

.delivery__container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);
    padding-bottom: 1rem;
}

.delivery__logo {
    width: 100%;
    margin-top: 1.5rem;
}

.delivery__instructions__header {
    font-size: 46px;
    color: rgb(216, 70, 72);
}

.delivery__instructions__one {
    font-size: 20px;
    background: rgb(216, 70, 72);
    color: white;
    font-weight: 200;
    letter-spacing: .1rem;
    line-height: 150%;
    padding-top: .05rem;
    padding-bottom: 1rem;
    padding-left: .5rem;
    padding-right: 1.5rem;
}

.delivery__instructions__two {
    font-size: 20px;
    background: rgb(2, 12, 126);
    color: white;
    font-weight: 600;
    line-height: 150%;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: .5rem;
    margin-top: 5rem;
}

.delivery__instructions__three {
    font-size: 22px;
    background: rgb(2, 12, 126);
    color: white;
    font-weight: 600;
    line-height: 150%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
}

.delivery__instructions__four {
    font-size: 48px;
    background: rgb(252, 13, 27);
    color: white;
    margin-bottom: 0;
}

.delivery__instructions__five {
    font-size: 20px;
    background: rgb(252, 13, 27);
    color: white;
}

.DayPickerNavigation_button {
    /* display: none; */
}

.DateInput_fang.DateInput_fang_1 {
    display: none;
}
.SingleDatePickerInput__withBorder {
    border: none;
    height: 0;
    padding: 0;
}
.SingleDatePicker {
    margin-left: -129%;
}
/* BRIGHT RED */
/* rgb(252, 13, 27) */
/* MUTED RED */
/* rgb(216, 70, 72) */
/* BLUE */
/* rgb(2, 12, 126) */
/* YELLOW */
/* rgb(232, 206, 124) */
p, h3 {
    font-family: 'Work Sans'
}

.receipt__header {
    background: rgb(237, 78, 78);
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.receipt__left__block {
    background: rgb(237, 78, 78);
    font-size: 18px;
}

.receipt__left__block p {
    padding-left: .75rem;
    text-align: left;
}

.receipt__left__image {
    width: 90%;
    margin: 0 auto !important;
}

.receipt__center__block {
    background: rgb(203 221 228);
    height: 20rem;
    padding-top: 1rem;
    margin-bottom: -3.25rem;
}

.receipt__menu__header {
    font-size: 34px;
    margin-top: 0px;
    font-weight: 900;
}

.receipt__menu__body {
    font-size: 24px;
    font-weight: 900;
    line-height: 200%;
}

.receipt__price__block { 
    background: rgb(34, 56, 119);
    color: white;
    text-align: left;
    padding-left: .75rem;
    font-size: 30px;
    font-weight: 700;
    width: 70%;
    margin: 0 auto;
    position: relative;
}

.receipt__right__block {
    background: rgb(245, 233, 71);
    font-size: 20px;
}

.receipt__right__image {
    width: 90%;
}

.receipt__payment__text {
    font-size: 22px;
    margin-top: 3rem;
}

.receipt__email__text {
    font-size: 24px;
    font-weight: 700;
}
@media all and (min-width: 768px) {
    .receipt__header {
        font-size: 38px;
    }
}
html {
    box-sizing: border-box;
    /* background-color: grey; */
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

