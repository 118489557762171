.dashboard-body {
    
}

.dashboard-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);
    box-shadow: 0 16px 40px rgba(0,0,0,0.12);
}

.dashboard-card-logo {
    height: 40%;
}

.dashboard-card-icon {
    height: 60%;
}

.reservation-container {
    /* border: 2px solid black; */
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 15px;
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
}

.reservation-name {
    margin-top: 0;
}

.reservation-column-header {
    margin-top: 0;
}

.details-container {
    padding: 0px 17px 17px 17px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
}

.dietary-restriction-tag-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    padding: 10px;
    margin-right: 5px;
}

.dietary-restriction-tag {
    margin: 0 auto;
}

.slot-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow:rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
}

.slot-container.empty {
    padding-top: 17px;
    padding-bottom: 17px;
}

.slot-text {
    margin: 0; 
    margin-left: 8px;
}

.slot-text.not-current {
    padding-top: 17px;
    padding-bottom: 17px;
}

@media only screen and (min-width: 768px) { 
    .dashboard-card {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 96px;
        margin-right: 96px;
    }

    .dashboard-card-logo {
        padding-top: 25px;
    }
}