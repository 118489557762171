.application-container {
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
}

.invitation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.invitation-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.invitation-modal-close:hover {
    cursor: pointer;
}

.invitation-modal-header-text {
    text-align: center;
}

.invitation-modal-body-text {
    text-align: center;
}

.invitation-modal-footer {
    text-align: center;
}

.denial-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.denial-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.denial-modal-close:hover {
    cursor: pointer;
}

.denial-modal-header-text {
    text-align: center;
}

.denial-modal-body-text {
    text-align: center;
}

.denial-modal-footer {
    text-align: center;
}

.contact-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.contact-modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: .5rem;
    outline: none;
}

.contact-modal-close:hover {
    cursor: pointer;
}

.contact-modal-header-text {
    text-align: center;
}

.contact-modal-body-text {
    text-align: center;
}

.contact-modal-footer {
    text-align: center;
}