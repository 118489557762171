.wrapper {
    padding: 0;
}

.input-field-wrapper {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    height: 12.5px;
    padding: 7.5px;
    margin: 2px;
    outline: none !important;
    font-size: 16px;
    font-weight: 300;
    /* color: rgba(0, 0, 0, 0.3); */
}

.input-field-wrapper::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.input-error-container {
    height: 22px;
    padding: 2px;
}

.input-error-message {
    margin: 0;
}
