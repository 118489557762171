.sq-fieldset {
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 5px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    width: 70%;
}


.square-payment-form-card-submit-container {
    margin: 8px;
}

.square-payment-form-card-submit {
    margin: 8px;
}

.square-payment-form-card-submit button {
    padding-top: 0px;
    padding-bottom: 0px;
}
button {
    background-color: #EF4C49;
    color: #FFFFFF;
    border: none;
    border-radius: 2.5px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 100%;
}

button:hover {
    cursor: pointer;
}

button:focus {
    outline: none;
}

button:active {
    background-color: #DF3C47;
}

button:disabled {
    background-color: #F5F5F5;
    color: rgba(0,0,0,0.25);
    border: 1px solid #D9D9D9;
    cursor: default;
}

button:hover {
    cursor: pointer;
}

.credit-card-wrapper {
    /* width: 75%; */
}
.credit-card-input-field {
    border-bottom: 1px solid black !important;
    padding-left: .5rem;
    margin-bottom: 1rem;
}