.reservation-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, .95);
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-bottom: 2.5%;
}

.confirmed-reservation-header {
    font-weight: bold;
    font-size:18px;
}

.confirmed-reservation-label {
    font-weight: bold;
}